// Libraries
import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Image, Menu, Popconfirm, Row, Spin, Table, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Component

// Styles
import './style.css';
import { useShareSelector } from '../../../../shared';
import moment from 'moment';
import { Mail } from 'react-feather';
import interceptor from '../../../../shared/interceptor';
import useToken from '../../../../hooks/useToken';

// Assets


const UserDetail = (props) => {

    const [loading, setLoading] = useState(false)
    const [userInfo, setuserInfo] = useState(null);
    const { token, removeToken } = useToken();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userInfo) {
            setLoading(true)
            interceptor.axiosGet("get", "/admin/user_detail/" + id, token).then(res => {
                debugger
                if (res.status == 200) {
                    let result = res.data
                    setuserInfo(res.data)
                }
                setLoading(false)
            })
        }

    }, [])


    return (
        <div className="site-card-border-less-wrapper">
            <Card className='cardCenter'>
                <div class="card-body text-left">
                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Email</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && <h3>{userInfo.email}</h3>}<br />
                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Phone Number</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && <h3>{userInfo.phoneNumber}</h3>}<br />
                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>First Name</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo && userInfo.details && <h3>{userInfo.details.first_name}</h3>}<br />
                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Last Name</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.last_name}</h3>}<br />
                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Address</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.last_name}</h3>}<br />
                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>What is your current price per gallon</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.currentPrice}</h3>}<br />
                        </Col>

                    </Row>
                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>How many fills do you typically get in one year</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.fillInYear}</h3>}<br />
                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>How many tanks do you use</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.tankUse}</h3>}<br />

                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Estimated yearly gallons</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.estimatedYearly}</h3>}<br />
                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Tank Size</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.tankSize}</h3>}<br />

                        </Col>

                    </Row>

                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Do you own the tank</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.tankOwn}</h3>}<br />
                        </Col>

                    </Row>
                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Is your tank burried</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{userInfo.details.tankBurried}</h3>}<br />
                        </Col>

                    </Row>
                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>What is the propane used for</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <h3>{JSON.parse(userInfo.details.propaneUsed).join(', ')}</h3>}<br />
                        </Col>

                    </Row>
                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Upload your latest invoice</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && userInfo.details && <Image src={userInfo.details.invoice} className='imgpro' />} <br />
                        </Col>

                    </Row>
                    <Row gutter={16} align='left'>
                        <Col xs={24} md={24} >
                            <h2>Date</h2>
                        </Col>
                        <Col xs={24} md={24} >
                            {userInfo && <h3>{moment(userInfo.createdAt).format('L - h:mm a')}</h3>}<br />


                        </Col>

                    </Row>

                </div>
            </Card>
        </div>
    );
}

export default UserDetail;