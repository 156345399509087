// Libraries
import React, { useEffect, useState } from 'react';
import { Layout, Image, Menu, Dropdown, Button, Avatar, Row, Col, Typography, Modal, Form, Input, Select } from 'antd';
import { Outlet, Link, useNavigate, useParams } from 'react-router-dom';
import { Facebook, Twitter, Youtube, Linkedin, ChevronDown, Menu as HamBurger, X } from 'react-feather';

// Component
import { EmailIcon, PhoneIcon } from '../../assets/icons/Icons';
import useToken from '../../hooks/useToken';

// Styles
import './style.css';

// Assets
import Logo from '../../assets/images/LPP.png';


const { Title } = Typography;

const { Header, Content, Footer } = Layout;

const mainMenu = [
    {
        label: (
            <Link to="/about-us">About Us</Link>
        ),
        key: 'about',
    },
    {
        label: (
            <Link to="/fitness-directory">Fitness Directory</Link>
        ),
        key: 'fitness',
    },
    {
        label: (
            <Link to="/blog">Blog</Link>
        ),
        key: 'blog',
    },
    {
        label: (
            <Link to="/contact-us">Contact Us</Link>
        ),
        key: 'contact',
    },
];

const AppLayout = () => {

    useEffect(() => {

    }, [])
    const [current, setCurrent] = useState('');
    return (
        <Layout>
            <div className='page-wrapper '>
                <div className='text-center'>
                    <a href="/">
                        <Image className="headImage" preview={false} src={Logo} />
                    </a>
                </div>

                <Content>
                    <Outlet />
                </Content>
                
            </div>
            <Footer className=''>
                    <div className='fs-footer-copyright'>© 2021. All rights reserved by Lowest Priced Propane</div>
                </Footer>
        </Layout>


    );
};

export default AppLayout;
