// Libraries
import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

// Component
import PublicRoute from './routes/PublicRoute';
import PrivateRoutes from './routes/PrivateRoutes';
import ShareRoute from './routes/shareRoute';

import Signin from './pages/auth/Signin';
import { AppLayout } from './components';


import HomePage from './pages/home';


// Styles
import './App.less';
import { Email, Hero, Tabs } from './pages/home/components';
import UserDetail from './pages/home/components/UserDetail';

function Entry() {
    return (
        <Routes>
            <Route element={<PublicRoute />}>
                <Route element={<AppLayout />}>
                    <Route path="/superadmin" element={<Navigate to="/superadmin/login" />} />
                    <Route path="superadmin/login" element={<Signin />} />
                </Route>

            </Route>
            <Route path="/" element={<ShareRoute />}>
                {/* <Route element={<AppLayout />}> */}
                <Route path="/" slug="Home" element={<HomePage />} />
                <Route path="form" element={<Tabs />} />

                {/* </Route> */}
            </Route>
            <Route path="/" element={<PrivateRoutes />}>
                <Route path="/" element={<AppLayout />}>

                    <Route path="dashboard" element={<Hero />} />
                    <Route path="form/:id/:type" element={<Email />} />
                    <Route path="detail/:id" element={<UserDetail />} />
                    {/* <Route path="/" element={<Navigate replace to="/myaccount" />} />
                    <Route path="HomePage" element={<HomePage />} /> */}
                    {/* <Route path="about-us" slug="About Us" element={<AboutPage />} />
                    <Route path="fitness-directory" slug="Fitness Directory" element={<FitnessDirectoryPage />} />
                    <Route path="contact-us" slug="Contact Us" element={<ContactPage />} />
                    <Route path="blog-detail" element={<BlogDetail />} /> */}
                </Route>

            </Route>
        </Routes>
    );
}

export default Entry;
