// Libraries
import React, { useState } from 'react';
import { Image, Row, Col, Typography, Button, Form, Input, Card } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

// Component
import useToken from '../../hooks/useToken';

// Styles
import './style.css';

// Assets
import AuthImg from '../../assets/images/LPP.png';
import Logo from '../../assets/images/LPP.png';
import domainurl from '../../constants/domainurl';
import OpenNotification from '../../components/Notifications';

const { Title, Text } = Typography;

const Signin = () => {

    const navigate = useNavigate();
    const { saveLoginUser } = useToken();
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const onFinish = (values) => {
        console.log('Success:', values);
        signInUser(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const signInUser = (values) => {
        setIsLoading(true);
        
        let bodyJson = {
            "email": values.email,
            "password": values.password,
        }

        fetch(domainurl + '/auth/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyJson)
        })
            .then((response) => response.json())
            .then((res) => {
                
                if (res.status == "ok") {
                     saveLoginUser(res.result);
                    navigate('/dashboard');
                }
                else {
                    if (res.errorCode) {
                        OpenNotification("topRight", res.message)
                        form.setFields([
                            {
                                name: 'password',
                                errors: [res.message],
                            },
                            {
                                name: 'email',
                                errors: [res.message],
                            },
                        ]);


                    }
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    return (
        <div className="site-card-border-less-wrapper">
            <Card className='cardCenter' style={{width:'680px'}}>
                <div class="card-body">
                    <Form
                        className='fs-auth-form'
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >

                        <Row gutter={16} align='middle'>
                            <Col xs={24} md={12} >
                                <Form.Item name="email" label="User Name:">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} >
                                <Form.Item name="password" label="Password:">
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} align='middle'>
                            <Col xs={24} md={24} >
                                <Button className='btn-submit' htmlType="submit" loading={isLoading}>Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
        </div>
    );
}

export default Signin;