// Libraries
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Image, Input, Menu, Popconfirm, Row, Modal, Table, Typography, Spin, Checkbox } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

// Component

// Styles
import './style.css';
import { useShareSelector } from '../../../../shared';
import moment from 'moment';
import { Eye, Mail, Trash, Trash2 } from 'react-feather';
import interceptor from '../../../../shared/interceptor';
import useToken from '../../../../hooks/useToken';
import OpenSuccessNotification from '../../../../components/Notifications/successNotif';

// Assets

const authMenu = (item) => (
    <Menu
        items={[
            {
                key: '1',
                label: (
                    <Link to={"/form/" + item._id + "/mail1"}>Create Offer</Link>
                ),
            },
            {
                key: '2',
                label: (
                    <Link to={"/form/" + item._id + "/mail2"}>Request Invoice</Link>
                ),
            },
            {
                key: '3',
                label: (
                    <Link to={"/form/" + item._id + "/mail3"}>Decline Offer</Link>
                ),
            },
            {
                key: '4',
                label: (
                    <Link to={"/form/" + item._id + "/mail4"}>Accept Request</Link>
                ),
            },
        ]}
    />
);

const { Title, Paragraph } = Typography;

const Hero = (props) => {
    const [page, setPage] = React.useState(1);
    const [deleteArr, setDeleteArr] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // Initialize selectedRowKeys as state
    const [selectedRow, setSelectedRow] = useState([]); // Initialize selectedRowKeys as state

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRow(selectedRows)
            console.log(`Selected Row Keys: ${selectedRowKeys}`, 'Selected Rows:', selectedRows);
        },
    };

    const columns = [
        {
            title: 'Sr #',
            dataIndex: 'sr#',
            width: '10px',
            // render: (text, object, index) => index + 1
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: '50px',
            render: (text, record) =>
                <Link to={'/detail/' + record._id} >{text}
                </Link>

        },
        {
            title: 'Number',
            dataIndex: 'phoneNumber',
            width: '20px',
        },
        {
            title: 'Price per Gallon',
            dataIndex: 'price',
            width: '100px',
            render: (text, record) => record.details ? record.details.currentPrice : '-'

        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            width: '50px',
            render: (text) => moment(text).format('L - h:mm a'),
        },
        {
            title: ' Mail',
            width: '50px',
            dataIndex: 'mail',
            render: (text, record) => <p className='fs-round-btn-small ant-btn ant-btn-primary' onClick={() => {
                setIsModalOpen(true)
                getUserEmails(record._id)
            }}>
                <Eye key={record._id} checked={text} size={18} />
            </p>,

        },

        {
            title: 'Send Mail',
            width: '50px',
            render: (text, record) =>
                <span className='trashClass'>
                    <Dropdown overlay={authMenu(record)} placement="bottom" >
                        <p className='fs-round-btn-small ant-btn ant-btn-primary'>
                            <Mail key={record._id} checked={text} size={18} />
                        </p>

                    </Dropdown>
                </span>

        },
        {
            title: 'Action',
            width: '50px',
            render: (text, record) =>
                <Popconfirm
                    title="Are you sure you want to delete.?"
                    onConfirm={() => deleteEntry(record._id)}
                >
                    <p className='fs-round-btn-small ant-btn ant-btn-primary'>
                        <Trash2 size={18} />
                    </p>
                </Popconfirm>

        }
    ];
    const [loading, setLoading] = useState(false)
    const [filterlist, setFilterlist] = useState(null);
    const [model, setModel] = useState(null)
    const [emailModel, setEmailModel] = useState(null)
    const { token, removeToken } = useToken();
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const getUserEmails = (id) => {
        setLoading(true)
        interceptor.axiosGet("GET", "/admin/getUserEmail/" + id, token).then(res => {
            if (res.status == 200) {
                let data = res.data.sort((a, b) => moment(a.createdAt).format('L - h:mm a') < moment(b.createdAt).format('L - h:mm a') ? 1 : -1,);
                setEmailModel(data)
            }
            setLoading(false)
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const deleteEntry = (id) => {
        setLoading(true)
        interceptor.axiosDelete("Delete", "/admin/" + id, null, token).then(res => {
            if (res.status == 200) {
                OpenSuccessNotification("topRight", 'Success', "Record delete Successfully")
                setLoading(false)
                res.data.forEach((x, i) => {
                    x.key = i + 1
                });
                setModel(res.data)
                setFilterlist(res.data)
            }
            setLoading(false)
        })
    }

    const deleteMultiEntry = () => {
        setLoading(true)
        let data = selectedRow.map(x => x._id)
        interceptor.axiosPost("Post", "/admin/deleteMultiUser", { data: data }, token).then(res => {
            if (res.status == 200) {
                OpenSuccessNotification("topRight", 'Success', "Record delete Successfully")
                setLoading(false)
                res.data.forEach((x, i) => {
                    x.key = i + 1
                });
                setModel(res.data)
                setFilterlist(res.data)
                setSelectedRowKeys([])
            }
            setLoading(false)
        })
    }


    useEffect(() => {

        if (!filterlist) {
            setLoading(true)
            interceptor.axiosGet("get", "/admin", token).then(res => {

                if (res.status == 200) {
                    res.data.forEach((x, i) => {
                        x.key = i + 1
                    });
                    let items = res.data.sort((a, b) => moment(a.createdAt).format('L - h:mm a') < moment(b.createdAt).format('L - h:mm a') ? 1 : -1,);
                    setModel(items)
                    setFilterlist(items)
                }
                if (res.status == 401) {
                    removeToken()
                    navigate('/superadmin/login');
                }
                setLoading(false)

            })
        }

    }, [])


    const onFinish = (values) => {
        console.log('Success:', values);
        let filter = model
        if (values.email) {
            filter = filter.filter(x => x.email.toLowerCase().includes(values.email.toLowerCase()))
        }
        if (values.phoneNumber) {
            filter = filter.filter(x => x.phoneNumber && x.phoneNumber.toLowerCase().includes(values.phoneNumber.toLowerCase()))
        }
        setFilterlist(filter)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = (e, id) => {
        if (e.target.checked) {
            setDeleteArr(current => [...current, id]);
        }
        else {
            setDeleteArr(deleteArr.filter(x => x != id));
        }
    };

    const deleteEmails = (e) => {
        console.log(deleteArr);
        setLoading(true)
        interceptor.axiosPost("Post", "/admin/deletUserEmails", { id: emailModel[0].user_id, data: deleteArr }, token).then(res => {

            if (res.status == 200) {
                let data = res.data.sort((a, b) => moment(a.createdAt).format('L - h:mm a') < moment(b.createdAt).format('L - h:mm a') ? 1 : -1,);
                setEmailModel(data)
            }

            setLoading(false)

        })
    };
    useEffect(() => { }, [deleteArr])

    return (
        <>
            <div className=" cardCenter cardDashboardCenter" >
                <div className=' ' >
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >
                        <Row gutter={16}>

                            <Col xs={24} sm={12} md={10} lg={10}>
                                <Form.Item
                                    name="email"
                                >
                                    <Input placeholder='Email' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={10} lg={10}>
                                <Form.Item
                                    name="phoneNumber"
                                >
                                    <Input placeholder='Phone Number' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={4}>
                                <div className='fs-right' style={{ "marginBottom": "24px" }}>
                                    {/* <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); }}>Reset</Button> */}
                                    <Button type="primary" htmlType="submit">Search</Button>
                                </div>
                            </Col>

                        </Row>

                    </Form>
                </div>
                {selectedRowKeys.length > 0 && <div style={{ textAlign: 'left' }} >
                    <Popconfirm
                        title="Are you sure you want to delete Selected Data.?"
                        onConfirm={() => deleteMultiEntry()}
                    >
                        <p className='ant-btn ant-btn-primary'>
                            <Trash2 size={18} style={{ marginTop: '' }} />Delete Selected
                        </p>
                    </Popconfirm>
                </div>}
                <Modal title="Email Detail" open={isModalOpen} onCancel={handleCancel} >
                    {loading ? <Spin ></Spin>
                        :
                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                            <Popconfirm
                                title="Are you sure you want to delete.?"
                                onConfirm={() => deleteEmails('')}
                            >
                                <p style={{ position: 'absolute', right: '50px' }} className='fs-round-btn-small ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </p>
                            </Popconfirm>

                            {emailModel && emailModel.length > 0 ? emailModel.map((item) => (
                                <div style={{ padding: "5px" }}>
                                    <Checkbox onChange={(e) => onChange(e, item._id)}>
                                        <p ><b>Date:</b>  {moment(item.createdAt).format('L - h:mm a')} </p>
                                        <b> Subject: {item.subject}</b>
                                        <p style={{ borderBottom: "1px dashed", }} dangerouslySetInnerHTML={{ __html: item.email }}></p>
                                    </Checkbox>
                                </div>
                            )) :
                                <p >  No Email</p>


                            }
                        </div>

                    }
                </Modal>
                <Table
                    className='dashboraTable'
                    scroll={{
                        x: 1300,
                    }}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filterlist}
                    loading={loading}
                    pagination={{
                        onChange(current) {
                            setPage(current);
                        }
                    }}
                />
            </div >
        </>

    );
}

export default Hero;