// Libraries
import { Button, Card, Checkbox, Col, Form, Input, notification, Row, Select, Upload, Alert, Image } from 'antd';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OpenNotification from '../../../../components/Notifications';
import OpenSuccessNotification from '../../../../components/Notifications/successNotif';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import interceptor from '../../../../shared/interceptor';
import ReCAPTCHA from 'react-google-recaptcha';
import headLogo from '../../../../assets/images/LPP.png';

// Component

// Styles
import './style.css';
const { Option } = Select;

// Assets
const options = [
    { label: 'Hot water', value: 'Hot water' },
    { label: 'Dryer machine', value: 'Dryer machine' },
    { label: 'Heating', value: 'Heating' },
    { label: 'Space heater', value: 'Space heater' },
    { label: 'Fireplace', value: 'Fireplace' },
    { label: 'Generator', value: 'Generator' },
    { label: 'Pool', value: 'Pool' },
    { label: 'Other', value: 'Other' },
];



const Tabs = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const captchaRef = useRef(null)
    const [message, setMessage] = useState();


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        getBase64(info.fileList[0].originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
        });
    };

    const onFinish = async (values) => {
        const token = captchaRef.current.getValue();
        if (token) {
            captchaRef.current.reset();
            console.log('Success:', values);
            signInUser(values);
        } else {
            setMessage("Please verify, you are not robot")
        }

    };

    const onChangeCaptcha = (value) => {
        setMessage('')
        if (value) {

        }
    }

    const signInUser = (values) => {
        console.log('Success:', values);
        setIsLoading(true)
        values.invoice = imageUrl
        values.propaneUsed = JSON.stringify(values.propaneUsed)
        let model = {
            email: localStorage.getItem("email"),
            details: values

        }
        interceptor.axiosPost("POST", "/users/save_user_detail", model).then(res => {
            if (res.status == 200) {
                OpenSuccessNotification("topRight", "Your form is submitted successfully", "we will get in touch with you soon!",)
                localStorage.removeItem("email")
                navigate('/');
            }
            if (res.status == "error") {
                OpenNotification("", res.message)
            }
            setIsLoading(false)


        }).catch(error => {
            setIsLoading(false)

            console.log(error)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChangeImage = (file) => {
        console.log('fileList', file.fileList);
    };

    return (
        <div className='page-wrapper '>
            <Row gutter={16}>
                <Col xs={0} sm={0} md={10} lg={12} style={{ padding: '0px', margin: '0px' }}>
                    <div className='text-center imag-cls'>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={12} style={{ borderRadius: '0' }}>
                    <div className='text-center'>
                        <a href="/">
                            <Image className="headImage headImagePadding"  preview={false} src={headLogo} />
                        </a>
                    </div>
                    <div className="site-card-border-less-wrapper propane">
                        <div className='cardCenter propaneMobile' >
                            <div class="">
                                <h2 class="title">Please fill out the form to determine if you are eligible for lowest price propane.</h2>
                                <Form
                                    className='fs-auth-form'
                                    layout="vertical"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                >
                                    <div className=''>


                                        <Row gutter={16} align='middle' >
                                            <Col xs={24} md={12} >
                                                <Form.Item name="first_name" label="First Name::">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="last_name" label="Last Name:">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16} align='middle'>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="address" label="Address:">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="currentPrice" label="What Is Your Current Price Per Gallon:">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16} align='middle'>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="fillInYear" label="How Many Fills Do You Typically Get In One Year:">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="estimatedYearly" label="Estimated Yearly Gallons: ">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16} align='middle'>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="tankUse" label="How Many Tanks Do You Use?:">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="tankSize" label="Tank Size:">
                                                    <Select
                                                        placeholder="Select Option"
                                                    >

                                                        <Option value="jack">100lb</Option>
                                                        <Option value="lucy">50 Gallon</Option>
                                                        <Option value="lucy">100 Gallon</Option>
                                                        <Option value="lucy">250 Gallon</Option>
                                                        <Option value="lucy">1000 Gallon</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16} align='middle'>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="tankOwn" label="Do You Own The Tank:">
                                                    <Select
                                                        placeholder="Select Option"
                                                    >

                                                        <Option value="jack">Yes</Option>
                                                        <Option value="lucy">No</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="tankBurried" label="Is Your Tank Burried"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please upload image',
                                                        },
                                                    ]}>
                                                    <Select
                                                        placeholder="Select Option"
                                                    >

                                                        <Option value="jack">Yes</Option>
                                                        <Option value="lucy">No</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="propaneUsed" label="What Is The Propane Used For:">
                                                    <Checkbox.Group options={options} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} >
                                                <Form.Item label="Upload Your Latest Invoice:"
                                                    name="invoice"
                                                >
                                                    <Upload
                                                        name="avatar"
                                                        listType="picture-card"
                                                        className="avatar-uploader"
                                                        showUploadList={false}
                                                        onChange={handleChange}

                                                        maxCount={1}
                                                        beforeUpload={(file) => {
                                                            return false
                                                        }
                                                        }
                                                    >
                                                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <Button ></Button>}
                                                    </Upload>
                                                </Form.Item>

                                            </Col>



                                        </Row>
                                        <Row gutter={16} align='middle'>
                                            <Col xs={24} md={24} >
                                                <ReCAPTCHA
                                                    ref={captchaRef}
                                                    sitekey="6Lca8WwoAAAAAEB603_mCJghVjczf7WZMvKG6r57"
                                                    onChange={onChangeCaptcha}
                                                />
                                                {message && <Alert
                                                    message={message}
                                                    type="error"
                                                />}
                                            </Col>
                                        </Row>

                                    </div>
                                    <Row gutter={16} align='middle'>
                                        <Col xs={24} md={24} >
                                            <Button className='btn-submit' htmlType="submit" loading={isLoading} >Next</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col >
            </Row >
        </div>
    );
}

export default Tabs;