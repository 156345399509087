export const LowestPricedPropaneOffer = [{
    type: "mail1",
    subject: "Lowest-Priced-Propane-Offer",
    body: `Hello,
   
Thank you for reaching out.
LowestPricedPropane has an offer of $$$ for you. If you are willing to proceed. Please approve the delivery time.
Delivery Time: 08:00 PM Monday
If you do not wish to proceed, reply NOPROPANE.

Yours Sincerely,
Lowestpriced propane,`,

    template: `<p><b>Hello</b> </p>
<p>Thank you for reaching out.</p><br>
<p>LowestPricedPropane has an offer of $$$ for you. If you are willing to proceed. Please approve the delivery time.</p><br>
<p>Delivery Time: 08:00 PM Monday</p><br>
<p>If you do not wish to proceed, reply NOPROPANE.</p><br><br>

<p>Yours Sincerely,</p><br>
<p>Lowestpriced propane,</p><br>`

},
{
    type: "mail2",
    subject: "Invoice-Request",
    body: `Hello,

Thank you for reaching out.
LowestPricedPropane would like to see your latest incoive in order to confirm details.
If you do not wish to proceed, reply with NOPROPANE.

Yours Sincerely,
Lowestpriced propane`,

    template: `<p><b>Hello</b> </p>
<p>Thank you for reaching out.</p><br>
<p>LowestPricedPropane would like to see your latest incoive in order to confirm details.</p><br>
<p>If you do not wish to proceed, reply NOPROPANE.</p><br><br>

<p>Yours Sincerely,</p><br>
<p>Lowestpriced propane,</p><br>`
}
    , {
    type: "mail3",
    subject: "Offer-Declined",
    body: `Hello,

Thank you for reaching out.
LowestPricedPropane has declined your offer. If you would like to submit another form, go to lowestpricedpropane.com
If you do not wish to proceed, reply NOPROPANE.

Yours Sincerely,
Lowestpriced propane`,

    template: `<p><b>Hello</b> </p>
<p>Thank you for reaching out.</p><br>
<p>LowestPricedPropane has declined your offer. If you would like to submit another form, go to lowestpricedpropane.com.</p><br>
<p>If you do not wish to proceed, reply NOPROPANE.</p><br><br>

<p>Yours Sincerely,</p><br>
<p>Lowestpriced propane,</p><br>`
},

{
    type: "mail4",
    subject: "Request-Accepted",
    body: `Hello,

    Thank you for reaching out.
    LowestPricedPropane has accepted your offer. Please confirm the delivery window.
    Delivery Time: 08:00 PM Monday
    If you do not wish to proceed, reply NOPROPANE.
    
    Yours Sincerely,
    Lowestpriced propane`,

    template: `<p><b>Hello</b> </p>
<p>Thank you for reaching out.</p><br>
<p>LowestPricedPropane has accepted your offer. Please confirm the delivery window..</p><br>
<p> Delivery Time: 08:00 PM Monday.</p><br><br>
<p>If you do not wish to proceed, reply NOPROPANE.</p><br><br>

<p>Yours Sincerely,</p><br>
<p>Lowestpriced propane,</p><br>`
}
]
