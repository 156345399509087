import { Button, Card, Checkbox, Col, Form, Input, notification, Row, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import OpenNotification from '../../../../components/Notifications';
import OpenSuccessNotification from '../../../../components/Notifications/successNotif';
import { Emailbody, LowestPricedPropaneOffer } from '../../../../data/emailTemplate';
import useToken from '../../../../hooks/useToken';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import interceptor from '../../../../shared/interceptor';
import { EditorState, convertFromRaw, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import './style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const Email = () => {
    const navigate = useNavigate();
    const { id, type } = useParams();
    const { token } = useToken();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );


    const onFinish = (values) => {
        setIsLoading(true)
        const rawContentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        console.log(rawContentState)
        values.user_id = id
        values.type = type
        values.email = rawContentState
        console.log('Success:', values);
        interceptor.axiosPost("POST", "/users/sendEmail", values, token).then(res => {
            setIsLoading(false)
            if (res.status == 200) {
                OpenSuccessNotification("topRight", "Success, Email Sent Successfully")
                navigate('/dashboard');
            }
            if (res.status == "error") {
                OpenNotification("", res.message)
            }
            setIsLoading(false)


        }).catch(error => {
            setIsLoading(false)

            console.log(error)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChangeImage = (file) => {
        console.log('fileList', file.fileList);
    };

    useEffect(() => {
        let val = LowestPricedPropaneOffer.find(x => x.type == type)
        const contentBlocks = convertFromHTML(val.template);
        const contentState = ContentState.createFromBlockArray(
            contentBlocks.contentBlocks,
            contentBlocks.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
        if (type)
            form.setFields([
                {
                    name: 'subject',
                    value: val.subject,
                },
            ]);
        form.setFields([
            {
                name: 'email',
                value: val.body

            },
        ]);
    }, [type])

    return (
        <div className="site-card-border-less-wrapper">
            <Card className='cardCenter'>
                <div class="card-body">
                    <Form
                        className='fs-auth-form'
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >

                        <Row gutter={16} align='middle'>
                            <Col xs={24} md={12} >
                                <Form.Item name="subject" label="Subject:">
                                    <Input title='Lowest-Priced-Propane-Offer' disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={24} >
                                <Form.Item name="email" label="Message:">
                                    {/* <Input.TextArea className='emailbodytext' /> */}
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={16} align='middle'>
                            <Col xs={24} md={24} >
                                <Button className='btn-submit' htmlType="submit" loading={isLoading} >Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
        </div>
    );
}

export default Email;