import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import interceptor from "../../../shared/interceptor";

export const getRolesDetails = createAsyncThunk(
	"user/detail",

	async (object, { dispatch, getState }) => {
		dispatch(gettingResponse())

		interceptor.axiosGet("get", "/users/" + (object.readOnly ? 'detailView/' : 'detail/') + object.userRoledId + '/true/' + object.tab, object.token, {

		}).then(res => {
			// saveToken(res)
			console.log(res)
			if (res.status == 200) {
			
				dispatch(gettingResponseSuccess(res.data.result))
				// dispatch(gettingUserRoles(res.data.result.userRolesInfo))
				dispatch(gettingUserRoles(res.data.result))

			} else {
				dispatch(gettingResponseSuccess({}))
			}


		}).catch(error => {
			dispatch(gettingResponseError(error))
		})

	},
);

const loginSlice = createSlice({
	name: "user_role",
	initialState: {

		userRoleData: null,
		userObjectData: null,
		status: "idle",
		isLoadingUserRole: false,
		error: null,
		isUserRoleError: false,
		userRolesArray: [],
		fitnessDirectory: 'Fitness Professionals'
	},
	reducers: {
		gettingResponseError: (state, action) => {
			state.status = "error";
			state.userRoleData = null;
			state.userObjectData = null;
			state.error = action.payload;
			state.userRolesArray = [];
			state.isLoadingUserRole = false;
			state.isUserRoleError = true;
		},
		gettingUserRoles: (state, action) => {

			state.status = "success";
			state.userRolesArray = action.payload.userRolesInfo
			state.userObjectData = action.payload
			state.isLoadingUserRole = false;
			state.isUserRoleError = false;
		},
		gettingResponseSuccess: (state, action) => {

			state.status = "success";
			state.userRoleData = action.payload;
			state.userObjectData = action.payload
			state.isLoadingUserRole = false;
			state.isUserRoleError = false;
		},
		gettingResponse: (state, action) => {
			state.status = "loading";
			state.userRolesArray = [];
			state.isLoadingUserRole = true;
			state.userRoleData = null;
			state.userObjectData = null;

			state.isUserRoleError = false;
		},

		setFitnessDirectory: (state, action) => {
			state.fitnessDirectory = action.payload;
		},
	},

});


// Action creators are generated for each case reducer function
export const { gettingResponse, gettingUserRoles, gettingResponseError,setFitnessDirectory, gettingResponseSuccess } =
	loginSlice.actions;

export default loginSlice.reducer;
