import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';

const ShareRoute = () => {
    const { token, setToken } = useToken();

    return  <Outlet />
}

export default ShareRoute;