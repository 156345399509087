
import { Button, Divider, notification, Space } from 'antd';
import React from 'react';

//topRight
//topLeft
const OpenNotification = (placement, desciption) => {
    notification.info({
        message: `Notification ${placement}`,
        description: desciption,
        placement,
    });

};

export default OpenNotification;