// Libraries
import React, { useEffect, useState } from 'react';
import { Facebook, Twitter, Linkedin } from 'react-feather';

// Component
import { Hero, Tabs } from './components';

// Styles
import './style.css';
import Logo from '../../assets/images/loginpage.avif';
import headLogo from '../../assets/images/LPP.png';
// Assets


import { Button, Card, Col, Form, Image, Input, InputNumber, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import interceptor from '../../shared/interceptor';
import OpenNotification from '../../components/Notifications';

const HomePage = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const onFinish = (values) => {
        console.log('Success:', values);
        setIsLoading(true)
        interceptor.axiosPost("POST", "/users/save_user", values).then(res => {
            debugger

            if (res.status == 200) {
                localStorage.setItem("email", values.email)
                navigate('/form');

            }
            if (res.status == "error") {
                OpenNotification("", res.message)
            }
            setIsLoading(false)


        }).catch(error => {
            console.log(error)
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
    }, [])

    return (
        <div className='page-wrapper '>
            <Row gutter={16}>
                <Col xs={0} sm={0} md={10} lg={12} style={{ padding: '0px', margin: '0px', }}>
                    <div className='text-center imag-cls'>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={12} style={{ borderRadius: '0' }}>
                    <div className='text-center'>
                        <a href="/">
                            <Image className="headImage headImagePadding" preview={false} src={headLogo} />
                        </a>
                    </div>
                    <div className="site-card-border-less-wrapper propane">
                        <div className='cardCenter propaneMobile'  >
                            <div class="" >

                                <h2 class="title">Please fill out the form to determine if you are eligible for lowest price propane.</h2>
                                <Form
                                    className='fs-auth-form'
                                    layout="vertical"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                    style={{ marginBottom: '5%' }}
                                >
                                    <div className=''>
                                        <Row gutter={16} align='middle'>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="email" label="Enter Email:"
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: 'Please input valid Email!',
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Please Enter Email',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} >
                                                <Form.Item name="phoneNumber" label="Enter Phone Number:"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Enter Phone Number',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} align='middle'>
                                            <Col xs={24} md={24} >
                                                <Button className='btn-submit' htmlType="submit" loading={isLoading} >Next</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>

                            </div>
                        </div>
                    </div>
                </Col >
            </Row >
        </div>

    );
}

export default HomePage;