
import { Button, Divider, notification, Space } from 'antd';
import React from 'react';

//topRight
//topLeft
const OpenSuccessNotification = (placement, message, desciption) => {
    notification.success({
        message: `${message}`,
        description: desciption,
        placement: 'topRight',
    });
};
export default OpenSuccessNotification;